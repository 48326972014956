import React from 'react'
import { FlexGrid } from '@telus-uds/components-web'
import { mapDataAttrsToDataSet } from '../../../utils/dataAttributes'

import { StyledBackgroundColor } from './Styles'

export type RowProps = {
  id?: string
  hasLimitWidth?: boolean
  hasGutter?: boolean
  hasOutsideGutter?: boolean
  rowBackgroundColor?: string
  rowBackgroundWidth?: 'Edge to Edge' | 'Limited Width'
  horizontalAlign?: 'start' | 'center' | 'end'
  verticalAlign?: 'top' | 'middle' | 'bottom'
  distribute?: 'around' | 'between'
  xsReverse?: boolean
  smReverse?: boolean
  mdReverse?: boolean
  lgReverse?: boolean
  xlReverse?: boolean
  children?: React.ReactNode
  equalColumnHeight?: boolean
}

const Row = ({
  id = undefined,
  hasLimitWidth = true,
  hasGutter = true,
  hasOutsideGutter = true,
  rowBackgroundColor = undefined,
  rowBackgroundWidth = undefined,
  horizontalAlign = undefined,
  verticalAlign = undefined,
  distribute = undefined,
  xsReverse = false,
  smReverse = false,
  mdReverse = false,
  lgReverse = false,
  xlReverse = false,
  children = undefined,
  equalColumnHeight = false,
  ...rest
}: RowProps) => {
  const hasBackground = rowBackgroundColor !== undefined || rowBackgroundWidth !== undefined
  const getHasLimitWidth = hasBackground && rowBackgroundWidth === 'Limited Width'
  const dataSet = mapDataAttrsToDataSet(rest)

  const renderRowWithBackgroundColor = ({ dataSet }) => (
    <FlexGrid limitWidth={getHasLimitWidth} gutter={false} dataSet={dataSet}>
      <FlexGrid.Row nativeID={id}>
        <FlexGrid.Col>
          <StyledBackgroundColor color={rowBackgroundColor}>{renderFlexGridRow()}</StyledBackgroundColor>
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  )

  const renderFlexGridRow = ({ dataSet }: { dataSet?: Record<string, string> } = {}) => (
    <FlexGrid
      limitWidth={hasLimitWidth}
      gutter={hasGutter}
      outsideGutter={hasOutsideGutter}
      dataSet={dataSet}
      testID={dataSet?.testid}
    >
      <FlexGrid.Row
        testID={'allium-row-testid'}
        nativeID={id}
        horizontalAlign={horizontalAlign?.toLowerCase()}
        verticalAlign={equalColumnHeight ? undefined : verticalAlign?.toLowerCase()}
        distribute={distribute?.toLowerCase()}
        xsReverse={xsReverse}
        smReverse={smReverse}
        mdReverse={mdReverse}
        lgReverse={lgReverse}
        xlReverse={xlReverse}
        dataSet={{ 'sb-field-path': '.children' }}
      >
        {React.Children.map(children, (child: React.ReactElement) => {
          return React.createElement(child.type, { ...child.props, equalColumnHeight })
        })}
      </FlexGrid.Row>
    </FlexGrid>
  )

  if (hasBackground) {
    return renderRowWithBackgroundColor({ dataSet })
  }
  return renderFlexGridRow({ dataSet })
}

export default Row
