import { IRowFields } from 'contentful-types'

import { BlockRenderer } from '../../../renderer/contentful/BlockRenderer'
import { withContentful } from '../../../renderer/contentful/withContentful'

import Row, { RowProps } from './Row'

const ContentfulRow = withContentful<IRowFields, RowProps>(Row, {
  id: (props) => props.fields.sectionId,
  children: (props) => {
    return <BlockRenderer block={props.fields.children} />
  },
  rowBackgroundColor: (props) => props.fields?.background?.fields?.color,
  rowBackgroundWidth: (props) => props.fields?.background?.fields?.width,
})

export default ContentfulRow
