import styled from 'styled-components'
import Palette from '@telus-uds/palette-allium/build/web/palette'
import koodoPalette from '@telus-uds/palette-koodo'
import { VIEWPORT_SIZES } from '@/constants/VIEWPORT'

const { color: koodoColors } = koodoPalette

type HexColorProps = {
  color: string
  viewport: string
}

const getKoodoTriColorGradient = (color1: string, color2: string, color3: string, viewport: string): string => {
  if (viewport === 'mobile') {
    return `linear-gradient(0deg, ${color3} 60px, ${color2} 60px, ${color2} 90px, ${color1} 90px);`
  }

  const bannerWidth = '1200px'
  const bannerStart = `calc((100% - ${bannerWidth}) / 2)`
  const bannerEnd = `calc(${bannerWidth} + ${bannerStart})`
  const bannerCutWidth = '150px'
  const bannerCutStart = `calc(${bannerEnd} - 160px)`
  const bannerCutEnd = `calc(${bannerCutStart} + ${bannerCutWidth})`

  return `linear-gradient(90deg,
    ${color1} 0px,
    ${color1} ${bannerCutStart},
    ${color2} ${bannerCutStart},
    ${color2} ${bannerCutEnd},
    ${color3} ${bannerCutEnd},
    ${color3} 100%)`
}

const koodoHexColors = (input: HexColorProps) => {
  return {
    'Koodo Brand Orange': koodoColors.orange,
    'Koodo Brand Green': koodoColors.green,
    'Koodo Brand Pink': koodoColors.pink,
    'Koodo Brand Blue': koodoColors.blue,
    'Koodo Brand Light Blue': koodoColors.blueLight,
    'Koodo Brand Purple': koodoColors.purple,
    'Koodo Brand Gradient OGP': getKoodoTriColorGradient(
      koodoColors.orange,
      koodoColors.green,
      koodoColors.pink,
      input.viewport
    ),
    'Koodo Brand Gradient OPB': getKoodoTriColorGradient(
      koodoColors.orange,
      koodoColors.purple,
      koodoColors.blue,
      input.viewport
    ),
    'Koodo Brand Gradient OPP': getKoodoTriColorGradient(
      koodoColors.orange,
      koodoColors.purple,
      koodoColors.pink,
      input.viewport
    ),
    'Koodo Brand Gradient GOP': getKoodoTriColorGradient(
      koodoColors.green,
      koodoColors.orange,
      koodoColors.pink,
      input.viewport
    ),
    'Koodo Brand Gradient GPO': getKoodoTriColorGradient(
      koodoColors.green,
      koodoColors.pink,
      koodoColors.orange,
      input.viewport
    ),
    'Koodo Brand Gradient GPB': getKoodoTriColorGradient(
      koodoColors.green,
      koodoColors.purple,
      koodoColors.blue,
      input.viewport
    ),
    'Koodo Brand Gradient BGO': getKoodoTriColorGradient(
      koodoColors.blue,
      koodoColors.green,
      koodoColors.orange,
      input.viewport
    ),
    'Koodo Brand Gradient BPO': getKoodoTriColorGradient(
      koodoColors.blue,
      koodoColors.pink,
      koodoColors.orange,
      input.viewport
    ),
    'Koodo Brand Gradient BPP': getKoodoTriColorGradient(
      koodoColors.blue,
      koodoColors.purple,
      koodoColors.pink,
      input.viewport
    ),
    'Koodo Brand Gradient BOPink': getKoodoTriColorGradient(
      koodoColors.blue,
      koodoColors.orange,
      koodoColors.pink,
      input.viewport
    ),
    'Koodo Brand Gradient BOPurple': getKoodoTriColorGradient(
      koodoColors.blue,
      koodoColors.orange,
      koodoColors.purple,
      input.viewport
    ),
    default: 'transparent',
  }
}

// TODO add additional gradient options once made available by UDS
// TODO move this into a constants file?
const hexColors = (input: HexColorProps) => {
  const colors = {
    'Athens Grey': Palette.color.greyAthens,
    Alabaster: Palette.color.greyAlabaster,
    'Mystic Grey': Palette.color.greyMystic,
    'TELUS Purple': Palette.color.purpleTelus,
    'Dark Purple': Palette.color.purpleDark,
    'Thunder Grey': Palette.color.greyThunder,
    White: Palette.color.white,
    'Purple to Pink (Gradient)': Palette.gradient.purple,
    'Grey to White (Gradient)': Palette.gradient.base,
    'Dark Purple to Lighter Purple (Gradient)': Palette.gradient.brand,
    'Dark Green to TELUS Green (Gradient)': Palette.gradient.green,
    'White to Grey (Neutral Gradient)': Palette.gradient.neutral,
    Black: Palette.color.black,
    ...koodoHexColors(input),
    default: 'transparent',
  }

  return colors[input.color] ? colors[input.color] : colors.default
}

export const StyledBackgroundColor = styled.div`
  width: 100%;
  background: ${(props: any) => `${hexColors({ color: props.color, viewport: 'mobile' })}`};

  @media (min-width: ${VIEWPORT_SIZES.lg}px) {
    background: ${(props: any) => `${hexColors({ color: props.color, viewport: 'desktop' })}`};
  }
`
